@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiPaper-root.MuiAppBar-root.MuiPaper-elevation4 {
  box-shadow: 0px 2px 8px 0px rgba(48, 52, 54, 0.10);
}

.carousel .control-dots {
  bottom: -2.6rem !important;
  z-index: 9999!important;
}

.carousel .control-dots li {
  height: .375rem!important; 
  width: .375rem!important;
  border-radius: 100%!important;
  box-shadow: none!important;
  border: 0!important;
  margin: 0 .125rem!important;
  background: #C5CBD0!important;
}

.carousel .control-dots li.selected {
  background: #2B363B!important; 
}

.carousel.carousel-slider {
  overflow: unset!important;
  margin-bottom: 4rem!important;
}
.thumbs-wrapper, .thumbs.animated{
  margin: 0!important;
}